import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image"
import { Row, Col, Typography } from "antd"

import Layout from "../../components/layout"
import Head from "../../components/head"
import BetaTestersLogos from "../../components/beta-testers-logos"
import IntegrationsLogos from "../../components/integrations-logos"
import FreeTrialCta from "../../components/free-trial-cta"
import CtaRow from "../../components/cta-row"

const { Title, Paragraph, Text } = Typography

const Startups = ({ location }) => {
  const data = useStaticQuery(graphql`
    query {
      oneImage: file(relativePath: { eq: "suivi-tresorerie-image-5.png" }) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH)
        }
      }
      twoImage: file(relativePath: { eq: "suivi-depenses-image-1.png" }) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH)
        }
      }
      threeImage: file(relativePath: { eq: "suivi-tresorerie-image-7.png" }) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH)
        }
      }
      fourImage: file(relativePath: { eq: "suivi-tresorerie-image-2.png" }) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH)
        }
      }
      benoitImage: file(relativePath: { eq: "benoit-maury.png" }) {
        childImageSharp {
          gatsbyImageData(width: 100)
        }
      }
      welybImage: file(relativePath: { eq: "welyb-logo.png" }) {
        childImageSharp {
          gatsbyImageData(height: 35)
        }
      }
      arrowOneImage: file(relativePath: { eq: "arrow-down-1.png" }) {
        childImageSharp {
          gatsbyImageData(height: 70)
        }
      }
      arrowTwoImage: file(relativePath: { eq: "arrow-down-2.png" }) {
        childImageSharp {
          gatsbyImageData(height: 70)
        }
      }
      arrowThreeImage: file(relativePath: { eq: "arrow-down-3.png" }) {
        childImageSharp {
          gatsbyImageData(height: 90)
        }
      }
    }
  `)

  const description = "Facilitez vos reportings investisseurs, contrôlez votre cash runway, suivez votre trésorerie en temps réel et simplifiez le rapprochement bancaire de votre startup avec l'outil de gestion de trésorerie RocketChart"

  return (
    <Layout>
      <Head
        title="Outil de gestion de trésorerie pour Startups"
        description={description}
        canonical="https://rocketchart.co/solutions/startups/"
      >
      </Head>

      <Row className="content-container space-around-row" gutter={40} type="flex" align="middle" style={{ marginRight: 0, marginLeft: 0 }}>
        <Col xs={{ span: 24, order: 1 }} lg={{ span: 13, offset: 0, order: 0 }}>
          <Title>Pourquoi des startups comme Welyb adorent RocketChart</Title>
          <Paragraph style={{ color: "black" }}>Automatisez vos reporting investisseurs, maîtrisez votre cash burn, suivez votre trésorerie en temps réel et simulez plusieurs scénarios prévisionnels. Tout simplement.</Paragraph>
          <CtaRow />
        </Col>
        <Col xs={{ span: 16, offset: 4, order: 0 }} lg={{ span: 11, offset: 0, order: 1 }} justify="center">
          <div style={{ marginBottom: "10px", marginRight: "10px" }}>
            <GatsbyImage image={data.welybImage.childImageSharp.gatsbyImageData} alt="logo Welyb" />
          </div>
          <Title level={4}>« RocketChart est un outil hyper intuitif me permettant de suivre ma tréso en temps réel. L'affectation automatique des opérations est très efficace et sa fonctionnalité d'export des données me permet de mettre à jour très facilement mon business plan sur Google Sheet. J'adore ! »</Title>
          <div style={{ display: "flex", flexDirection: "row" }}>
            <GatsbyImage image={data.benoitImage.childImageSharp.gatsbyImageData} alt="Benoît Maury - Co-fondateur & CEO de Welyb" />
            <div style={{ display: "flex", flexDirection: "column", justifyContent: "center", marginLeft: "10px" }}>
              <Text strong>Benoît Maury</Text>
              <Text>Co-fondateur & CEO - Welyb</Text>
            </div>
          </div>
        </Col>
      </Row>

      <Row className="content-container" gutter={40} type="flex" justify="center" style={{ marginTop: "120px" }}>
        <Col span={24} align='center'>
          <Text strong style={{ color: '#6a737d' }}>Ils pilotent leur trésorerie en temps réel avec RocketChart</Text>
        </Col>
        <BetaTestersLogos />
      </Row>

      <Row className="content-container" type="flex" justify="center" style={{ marginTop: "80px" }}>
        <Col xs={24} md={16}>
          <div align='center'>
            <GatsbyImage image={data.arrowTwoImage.childImageSharp.gatsbyImageData} align='center' alt="arrow down 2" />
          </div>
        </Col>
      </Row>

      <Row className="content-container space-around-row" gutter={40} type="flex" align="middle">
        <Col xs={{ span: 24, order: 1 }} lg={{ span: 12, order: 0 }}>
          <GatsbyImage image={data.oneImage.childImageSharp.gatsbyImageData} alt="Automatisez votre reporting avec la synchronisation bancaire et catégorisation automatique" />
        </Col>
        <Col xs={{ span: 24, order: 0 }} lg={{ span: 12, order: 1 }}>
          <Title level={2}>Gagnez du temps avec un reporting automatisé</Title>
          <Paragraph style={{ color: "black" }}>Connectez vos comptes bancaires, personnalisez vos catégories et classez automatiquement vos opérations pour automatiser vos tableaux de reporting de trésorerie. Exportez vos données en 1 clic ou partagez un accès à vos investisseurs.</Paragraph>
        </Col>
      </Row>

      <Row className="content-container space-around-row" gutter={40} type="flex" align="middle">
        <Col xs={24} lg={12}>
          <Title level={2}>Maîtrisez votre runway et vos dépenses pour survivre</Title>
          <Paragraph style={{ color: "black" }}>Suivez l’évolution de vos dépenses et calculez votre cash runway à partir de vos données réelles. Gardez un oeil sur vos indicateurs et identifier les coûts à réduire en quelques clics pour que votre Startup tienne.</Paragraph>
        </Col>
        <Col xs={24} lg={12}>
          <GatsbyImage image={data.twoImage.childImageSharp.gatsbyImageData} alt="Maîtrisez les dépenses de votre startup" />
        </Col>
      </Row>

      <Row className="content-container space-around-row" gutter={40} type="flex" align="middle">
        <Col xs={{ span: 24, order: 1 }} lg={{ span: 12, order: 0 }}>
          <GatsbyImage image={data.threeImage.childImageSharp.gatsbyImageData} alt="Comparaison objectifs vs réalité" />
        </Col>
        <Col xs={{ span: 24, order: 0 }} lg={{ span: 12, order: 1 }}>
          <Title level={2}>Comparez l’écart entre vos prévisions et la réalité</Title>
          <Paragraph style={{ color: "black" }}>Définissez vos objectifs annuels via vos budgets prévisionnels. Mesurez à tout moment votre performance et votre croissance en comparant automatiquement où vous en êtes dans la réalité. Ajustez vos prévisions et identifiez les opportunités de développement pour croître plus rapidement.</Paragraph>
        </Col>
      </Row>

      <Row className="content-container space-around-row" gutter={40} type="flex" align="middle">
        <Col xs={24} lg={12}>
          <Title level={2}>Ne courez plus après les justificatifs à la fin du mois</Title>
          <Paragraph style={{ color: "black" }}>Ajoutez un justificatif à chaque transaction ou rapprochez une facture, et vérifiez que toutes les opérations sont justifiées. Facilitez la transmission des éléments avec votre comptable en lui créant un accès dédié.</Paragraph>
        </Col>
        <Col xs={24} lg={12}>
          <GatsbyImage image={data.fourImage.childImageSharp.gatsbyImageData} alt="Ajoutez vos justificatifs pour cadrer votre comptabilité" />
        </Col>
      </Row>

      <Row className="content-container" type="flex" justify="center" style={{ marginTop: "80px", marginBottom: "80px" }}>
        <Col xs={24} md={16}>
          <div align='center'>
            <GatsbyImage image={data.arrowOneImage.childImageSharp.gatsbyImageData} align='center' alt="arrow down" />
          </div>
        </Col>
        <Col span={12} align='center' style={{ marginTop: '20px' }}>
          <Title level={2}>Intégrez vos outils de facturation, ERP et logiciels de comptabilité</Title>
          <Paragraph style={{ color: "black" }}>Synchronisez en temps réel vos factures dans RocketChart pour générer automatiquement votre prévisionnel de trésorerie et automatiser le rapprochement banque / facture.</Paragraph>
        </Col>
        <Col span={24} align='center' style={{ marginTop: '20px' }}>
          <IntegrationsLogos />
        </Col>
      </Row>

      <Row className="content-container" type="flex" justify="center" style={{ marginTop: "0px", marginBottom: "60px" }}>
        <Col xs={24} md={16}>
          <div align='center'>
            <GatsbyImage image={data.arrowThreeImage.childImageSharp.gatsbyImageData} align='center' alt="arrow down 3" />
          </div>
        </Col>
        <Col xs={24} align='center' style={{ marginTop: '20px' }}>
          <Title level={2}>Démarrez maintenant votre essai gratuit</Title>
          <FreeTrialCta />
        </Col>
      </Row>

    </Layout>
  )
}

export default Startups